@import '../../common.scss';

.admin {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: $color-white;
  .request-help {
    width: fit-content;
    display: block;
    margin-top: 25px;
    margin-left: 40px;
    a {
      color: $color-chard-green;
    }
    @media (max-width: 620px) {
      text-align: start;
      padding: 0 16px;
      box-sizing: border-box;
      text-align: center;
    }
  }

  > .row {
    display: flex;

    > .column-menu {
      display: flex;
      flex-direction: column;
      background-color: #f9f7f5;
      flex-basis: 100%;
      width: 255px;
      padding: 14px 13px 42px;
      align-items: flex-start;
      max-width: 255px;
      min-width: 255px;
      word-break: break-word;
      justify-content: space-between;
      height: auto;
      min-height: calc(100vh - 84px);
      @media (max-width: 620px) {
        display: none;
      }
      @include ignore-padding();
      h2 {
        color: $color-chard-green;
        font-size: 36px;
      }
      .info {
        margin: 15px 0px 8px 0px;
        padding-left: 13px;
        h5 {
          margin: 0px;
          color: $color-grey;
          font-size: 20px;
        }

        label {
          margin-top: 15px;
          font-size: 16px;
          margin-bottom: 20px;
        }

        a {
          color: $color-black;
          text-decoration: none;
        }
      }
      .list-box {
        width: 100%;
      }
      .link {
        &.action {
          width: -webkit-fill-available;
          height: 43px;
          font-size: 16px;
          // font-family: "SharpGroteskBook19";
          text-transform: capitalize;
          padding: 11px 0 8px 13px;
          text-align: left;
          display: flex;
          align-items: center;
          color: $color-chard-green;
          text-decoration: none;
          p {
            color: $color-chard-green;
          }
          &.selected {
            background-color: #f2efeb;
          }
        }
        &:hover {
          background-color: #f2efeb;
        }

        a {
          text-decoration: none;
          font-family: 'SharpGroteskBook19';
          color: $color-chard-green;
          line-height: 24px;
        }

        .dash-nav {
          button {
            width: fit-content;
          }
        }
      }

      hr {
        margin: 25px 0px 10px 0px;
        width: 42px;
        border-top: 4px solid $color-secondary;
        opacity: 0.25;
      }
    }

    > .column-main {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media (max-width: 1440px) {
        width: calc(100vw - 255px);
      }
      @media (max-width: 620px) {
        width: 100%;
      }
      @include ignore-padding();

      .main {
        flex-direction: column;
        max-width: 670px;
        width: 62%;
        @media (max-width: 768px) {
          width: 100%;
        }
      }

      // .plan-info {
      //   flex-direction: column;
      //   @media (min-width: 768px) {
      //     flex-direction: row;
      //   }
      //   .grow {
      //     margin-bottom: 15px;
      //   }
      //   @media (min-width: 768px) {
      //     height: 70px;
      //   }
      //   border-bottom: 1px solid rgba(0, 0, 0, 0.41);
      //   padding-bottom: 30px;
      //   margin-bottom: 30px;
      //   .head {
      //     color: #000000;
      //     opacity: 0.35;
      //     font-family: "GothamCond-Medium";
      //     margin-bottom: 9px;
      //     font-size: 18px;
      //     line-height: 16px;
      //   }
      //   h4 {
      //     font-family: "GothamCond-Book";
      //     .count {
      //       font-size: 36px;
      //     }
      //     font-size: 22px;
      //   }
      //   .small-print {
      //     font-family: "GothamCond-Book";
      //     font-size: 16px;
      //     line-height: 13px;
      //   }
      // }

      // .invites-list {
      //   @media (min-width: 768px) {
      //     padding-right: 50px;
      //   }

      //   h4 {
      //     font-family: "GothamCond-Book";
      //     ;
      //     font-size: 18px;
      //   }
      // }
    }
  }

  .back {
    display: flex;
    margin: 116px 0 0 116px;
    text-decoration: none;
    color: black;
    font-weight: 700;
  }

  .controls {
    > * {
      margin-right: 10px;
    }
  }

  .invite-members {
    box-shadow: 0px 0px 55px 0px rgba(0, 0, 0, 0.1);
    text-align: left;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;

    .invite-members-box {
      padding-left: 36px;
      margin-top: 43px;
      .invite-single-member {
        display: flex;
        flex-direction: row;
        border: 1px solid $color-divider;
        border-radius: 100px;
        padding: 5px;
        width: 442px;
        justify-content: space-between;
        input::placeholder {
          font-size: 14px;
        }
        @media (max-width: 768px) {
          width: 90%;
        }
        .modal-email-input {
          border: none;
          width: 315px;
        }

        .invite-single-member-button {
          color: $color-white;
          background-color: $color-chard-green;
          width: 130px;
          &.disabled {
            background-color: $color-divider;
            opacity: 1;
          }
        }
      }
    }
    .upload-member-button {
      margin: 10px;
      width: fit-content;
    }
    .invites {
      min-height: 200px;
    }
  }
}
