@import '../../common.scss';

.get-in-touch {
  background-color: $reskin-beige;
  color: $reskin-green;
  text-align: center;
  padding: 20px;

  img {
    height: 63px;
    margin: 80px 0px 40px 0px;
  }

  h1 {
    font-size: 10vw;
    padding-bottom: 12px;
    margin-bottom: 20px;
  }

  p {
    @include reskin-fonts('h4');
    font-size: 24px;
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    display: inline;

    a {
      color: $reskin-green;
    }
  }

  @media (min-width: 385px) {
    h1 {
      font-size: 64px;
    }
  }
}
