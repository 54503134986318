@import '../../common.scss';

.column-menu {
  margin: 0 20px 30px;
  @media (min-width: 768px) {
    margin: 0;
  }

  .dash-nav {
    img {
      margin-top: -3px !important;
      height: 15px;
    }
  }
}

.column-main {
  &.edit-plan {
    margin: 15px;
    padding: 0 0 50px 0 !important;

    > div {
      @include ignore-padding();
    }

    > div:first-child {
      width: 100%;
      padding: 5% 5% 0 5%;
    }
    > div:nth-child(2),
    > div:nth-child(3) {
      width: 100%;
      padding: 5% 5% 0 5%;
    }
  }

  input:read-only {
    opacity: 0.5;
  }

  .edit-plan-top {
    @include ignore-padding();
    width: 100%;
    .confirm-cancel {
      display: flex;
      // flex-direction: column;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      .grid-loader {
        width: 14px;
      }
    }
    .confirm-button {
      margin-bottom: 12px;
      align-self: flex-end;
      min-width: 92px;
      height: 20px;
      background-color: $color-tertiary;
      font-size: 20px;
    }
    .cancel-link {
      color: black;
      text-decoration: underline;
    }
  }
  .plan-calc {
    justify-content: space-between;
    margin-bottom: 20px;

    .input-number,
    .input-number button {
      font-size: 26px;
    }
    .input-number,
    .plan-details {
      display: inline-block;
    }

    .plan-details {
      padding-top: 4px;
      flex-grow: 1;
      font-size: 14px;
      p,
      div {
        display: inline-block;
        margin-bottom: 5px;
      }

      .cost {
        margin-left: 5px;
        font-size: 22px;
        line-height: 27px;
      }

      .text {
        font-size: 14px;
        line-height: 17px;
        margin-left: 5px;
      }
      input {
        color: $color-chard-green;
      }
    }

    .additional-payment {
      max-width: 450px;
      margin-top: 10px;
      font-size: 14px;
    }
  }

  .account-padding {
    margin-top: 70px;
  }
}

.change-password-button {
  margin-top: 15px;
}

.change-password {
  background-color: $color-tertiary;
}
