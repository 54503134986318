@import '../../common.scss';

.signup {
  .head-text {
    margin-bottom: 16px;
    @include reskin-fonts('copy-S');
    margin-bottom: -40px;
    max-width: 960px;
    padding: 24px 46px;
    width: 81vw;

    a {
      @include reskin-fonts('copy-S');
      text-decoration: underline;
    }
  }

  video {
    width: 100%;
  }

  .signup-form-container {
    background-color: $reskin-white;
    display: flex;
    justify-content: center;

    > .column {
      width: 100%;
    }

    h1 {
      @include reskin-fonts('h1');
      width: 100%;
      text-align: center;
    }

    h2 {
      @include reskin-fonts('h2');
      width: 100%;
      text-align: center;

      &.get_started {
        margin: 80px 0px;
      }
    }

    .options {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1070px;
      margin: auto;

      @media screen and (max-width: 1123px) {
        flex-direction: column;
      }

      > h2 {
        width: 50px;
        margin: 10px 60px;
      }

      .option {
        &[data-selected='false'] {
          opacity: 0.6;
          border: none;
        }

        border: $reskin-green 3px solid;
        background-color: $reskin-beige;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        display: flex;
        cursor: pointer;
        width: 455px;
        height: 455px;
        max-width: 455px;
        max-height: 455px;
        border-radius: 20px;

        @media screen and (max-width: 1123px) {
          flex-direction: column;
          width: calc(90vw - 30px);
          padding: 15px;
        }

        h2 {
          @include reskin-fonts('h2');
          margin-bottom: 15px;
        }

        h3 {
          @include reskin-fonts('h3');
          margin-bottom: 35px;
        }

        li {
          @include reskin-fonts('copy-S');
        }

        ul {
          display: flex;
          flex-direction: column;
          align-items: center;

          li {
            text-align: center;
            list-style-position: inside;
            padding-top: 20px;
          }
        }
      }
    }

    .input-area {
      width: 100%;
      max-width: 660px;
      padding: 80px 20px;
      box-sizing: border-box;
      margin-right: auto;
      margin-left: auto;

      label:not(#signup_price):not(#signup_quant):not(#signup_agree) {
        display: none;
      }

      .inputs {
        @media (min-width: 768px) {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          justify-content: stretch;
          > * {
            width: 100%;
            flex-grow: 1;
            flex-shrink: 1;

            clear: both;
            margin-right: 10px;

            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
      .members-container {
        padding: 40px 0 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 90%;
        margin: 0 auto;
        text-align: center;

        .input-number {
          margin: 10px auto;

          label {
            display: block;
            // .input{
            //   font-size: 32px !important;
            // }
          }
        }

        .members-text {
          * {
            @include reskin-fonts('copy-S');
            text-align: center;
          }
        }

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: center;
          gap: 20px;

          .input-number {
            margin: 0 8px 0 0;
          }

          .members-text {
            text-align: left;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
          }
        }

        @media (min-width: 768px) {
          .members-text {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 26px;

            .total-cost {
              display: flex;
              flex-direction: row;
            }
          }
        }
      }

      .input-checkbox {
        margin: 10px auto;

        label.container {
          display: block;
        }
      }

      .help-description {
        padding-top: 25px;
        display: flex;
        flex-direction: column;
        label {
          font-family: 'GothamCond-Medium';
          font-size: 18px;
          text-align: left;
          color: black;
          opacity: 0.5;
        }
        textarea {
          all: unset;
          appearance: none;
          width: unset;
          max-width: unset;
          min-width: unset;

          @include reskin-fonts('statement-medium') {
            color: $reskin-green;
          }

          border-style: solid;
          background-color: transparent;
          border-color: rgba(47, 71, 62, 0.3);
          border-radius: 10px;
          text-align: left;
          font-size: 16px;
          line-height: 20px;
          padding: 18px 20px;

          &::placeholder,
          &::-webkit-input-placeholder {
            @include reskin-fonts('statement-medium');
            text-transform: uppercase;
            color: rgba(47, 71, 62, 0.4);
          }
        }
      }
      .button {
        background: $reskin-green;
        color: $reskin-beige;
        padding: 10px;
        margin-top: 60px;
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
        img {
          width: 24px;
          padding: 0 8px;
        }
        padding: 18px 20px 15px;
        border-radius: 10px;
        background: $reskin-green;
        transition: opacity 150ms ease-out;
        width: max-content;

        @include reskin-fonts('statement-medium') {
          text-transform: uppercase;
          color: $reskin-beige;
        }

        &:hover {
          opacity: 0.7;
          transition: opacity 150ms ease-out;
        }
      }
    }
  }

  .foot-text {
    margin: 24px;
    text-align: center;
    font-size: 20px;

    a {
      color: $reskin-green;
    }
  }

  .newsletter {
    display: none;

    background-color: $color-secondary;
    color: $color-white;
    text-align: center;
    padding: 15px;
    margin: 15px 15px 80px 15px;
    .newsletter-form {
      @media (min-width: 768px) {
        display: flex;
        justify-content: space-around;
        :nth-child(1) {
          flex-grow: 1;
          margin: 0 10px;
        }
        :nth-child(2) {
          flex-grow: 2;
          margin: 0 10px;
        }
        :nth-child(3) {
          flex-grow: 1;
          padding: 0;
        }
      }
    }
    h1 {
      font-size: 50px;
    }
    button {
      background-color: transparent;
      color: white;
      border: none;
      font-size: 34px;
      font-family: 'GothamCond-Medium';
    }
  }
}
.app-info {
  font-size: 11px;
  text-align: right;
  font-family: 'GothamCond-Book';
}
