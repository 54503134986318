@mixin ignore-padding {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

$breakpoints: (
  xs: 375px,
  sm: 570px,
  md: 768px,
  lg: 1024px,
  xl: 1440px,
  xxl: 1800px,
);

@mixin devices($breakpoint) {
  @media only screen and (max-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}
