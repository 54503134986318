@import '../../reskin-2023.scss';
@import '../../mixins.scss';

button {
  border: none;
  cursor: pointer;
  height: fit-content;
  background-color: inherit;
}

.styled-text {
  &-input {
    display: block;
    border: 1px solid $web-chard-light;
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
    color: $web-chard-green;
    width: 100%;
  }

  &-error {
    margin-top: 0.5rem;
    color: $system-error;
  }
}

.styled-button {
  border: 1px solid $web-chard-green;
  border-radius: 100px;
  transition: background-color ease 150ms, color ease 150ms;
  flex-wrap: nowrap;

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &_primary {
    background-color: $web-chard-green;
    color: $web-white;

    @include hover {
      background-color: $web-white;
      color: $web-chard-green;
    }

    &:disabled {
      border-color: $web-chard-light;
      background-color: $web-chard-light;
    }
  }

  &_small {
    padding: 0.5rem 0.5rem;
  }

  &_medium {
    padding: 0.5rem 2.5rem;
  }

  &_large {
    padding: 1rem 3.125rem;
  }

  &_fullWidth {
    width: 100%;
  }
}

.layout {
  width: 100%;
  min-height: 100vh;
  background: $web-white;
  display: grid;
  grid-template-rows: max-content max-content 1fr max-content;

  * {
    box-sizing: border-box;
  }

  .layout-header {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    padding: 1rem;
    align-items: center;
    gap: 0.5rem;

    .layout-header-help {
      justify-self: flex-end;
      text-align: right;
    }

    @include devices(md) {
      grid-template-columns: 0 max-content 1fr;
      .layout-logo {
        width: 140px;
      }
    }
  }

  .layout-footer {
    background: $web-chard-green;
    color: $web-white;
    padding: 2.5rem;
    text-align: center;
  }
}

.progress {
  width: 100%;
  border: 1px solid $web-divider;
  border-width: 1px 0;

  .progress-inner {
    width: 100%;
    max-width: map-get($breakpoints, xl);
    margin: 0 auto;
    padding: 1rem 1rem;
  }

  .progress-steps {
    display: flex;
    gap: 1.5rem;
    list-style-type: none;
  }

  .progress-step {
    color: $web-chard-light;
  }

  .progress-step_active {
    color: $web-chard-green;
  }
}

.mainSection {
  display: grid;
  grid-template-columns: 60% 40%;
  max-width: map-get($breakpoints, xl);
  margin: 0 auto;

  @include devices(md) {
    display: block;
  }
}

.signup {
  padding: 2rem 1.5rem;

  .signup-title {
    font-size: map-get($typography-heading, 3);
    @include devices(md) {
      display: none;
    }
  }

  .signup-subtitle {
    font-size: map-get($typography-heading, 4);
    margin-bottom: 0.75rem;
  }

  .signup-form > section {
    margin: 2rem 0;
    @include devices(md) {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  .signup-members {
    .signup-members-inputRow {
      display: flex;
      align-items: center;
      gap: 0.75rem;
    }
    .signup-members-button {
      width: 2rem;
      height: 2rem;
      background-color: $web-almond-cream;
      border-radius: 32px;
    }
  }

  .signup-numInput {
    border-radius: 8px;
    border: 1px solid $web-divider;
    padding: 0.5rem 0.75rem;
    width: 4.5rem;
    text-align: center;
    background-color: inherit;
    appearance: textfield;
    font-family: inherit;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .signup-textInput {
    display: block;
    margin: 1.5rem 0;
  }

  .signup-selectInput {
    @extend .styled-text-input;
    appearance: none;
    font-family: inherit;
    width: 50%;
    min-width: fit-content;
    background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'><path d='M480-380 276-584l20-20 184 184 184-184 20 20-204 204Z'/></svg>")
      no-repeat;
    background-position: calc(100% - 0.5rem) center;

    @include devices(md) {
      width: 100%;
    }
  }
}

.summary {
  background-color: $web-almond-cream-light;
  padding: 1.5rem 2.5rem;

  .summary-title {
    font-size: map-get($typography-heading, 4);
  }

  hr {
    margin: 1rem 0;
    border: 1px solid $web-divider;
    height: 0px;
  }

  .summary-price {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 0.5rem;
    dd {
      text-align: right;
    }
  }

  .summary-cta {
    margin: 1.75rem 0 1rem;

    .summary-cta-disclaimer {
      color: $web-chard-light;
      text-align: center;
      margin: 0.75rem 0;
    }
  }

  .summary-error {
    margin-top: 0.75rem;
    text-align: center;
    color: $system-error;
  }
}
