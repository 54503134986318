@import './common';
@font-face {
  font-family: 'BrownTT-Regular';
  src: local('BrownTT-Regular'),
    url(./assets/fonts/BrownTT-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'BrownTT-Bold';
  src: local('BrownTT-Bold'),
    url(./assets/fonts/BrownTT-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'FreightBig-Pro';
  src: local('FreightBig-Pro'),
    url(./assets/fonts/FreightBig-Pro.woff) format('woff');
}

@font-face {
  font-family: 'Gotham-Book';
  src: local('Gotham-Book_Web'),
    url(./assets/fonts/Gotham-Book_Web.woff) format('woff');
}

@font-face {
  font-family: 'Gotham-Medium';
  src: local('Gotham-Medium_Web'),
    url(./assets/fonts/Gotham-Medium_Web.woff) format('woff');
}

@font-face {
  font-family: 'GothamCond-Light';
  src: local('GothamCond-Light_Web'),
    url(./assets/fonts/GothamCond-Light_Web.woff) format('woff');
}

@font-face {
  font-family: 'GothamCond-Book';
  src: local('GothamCond-Book_Web'),
    url(./assets/fonts/GothamCond-Book_Web.woff) format('woff');
}

@font-face {
  font-family: 'GothamCond-Medium';
  src: local('GothamCond-Medium_Web'),
    url(./assets/fonts/GothamCond-Medium_Web.woff) format('woff');
}

@font-face {
  font-family: 'GothamCond-Bold';
  src: local('GothamCond-Bold_Web'),
    url(./assets/fonts/GothamCond-Bold_Web.woff) format('woff');
}

@font-face {
  font-family: 'GothamCond-Bold';
  src: local('GothamCond-Bold_Web'),
    url(./assets/fonts/GothamCond-Bold_Web.woff) format('woff');
}

/*
  Reskin fonts
*/

@font-face {
  font-family: 'SharpGroteskBook19';
  src: url('./assets/fonts/reskin/SharpGroteskBook19.otf') format('opentype');
}

@font-face {
  font-family: 'Simula Italic';
  src: url('./assets/fonts/reskin/Simula Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Simula';
  src: url('./assets/fonts/reskin/Simula.ttf') format('truetype');
}

@font-face {
  font-family: 'Jost';
  src: url('./assets/fonts/reskin/Jost.woff2') format('truetype');
}

* {
  margin: 0px;
  padding: 0px;
}

$padding1: 10px;
$padding2: 30px;

.p-1,
.pb-1,
.pt-1,
.pl-1,
.pr-1 {
  @include ignore-padding();
}

.p-1 {
  padding: $padding1;
}
.pb-1 {
  padding-bottom: $padding1;
}
.pt-1 {
  padding-top: $padding1;
}
.pr-1 {
  padding-right: $padding1;
}
.pl-1 {
  padding-left: $padding1;
}

.m-1 {
  margin: $padding1;
}
.mb-1 {
  margin-bottom: $padding1;
}
.mt-1 {
  margin-top: $padding1;
}
.mr-1 {
  margin-right: $padding1;
}
.ml-1 {
  margin-left: $padding1;
}

.p-2,
.pb-2,
.pt-2,
.pl-2,
.pr-2 {
  @include ignore-padding();
}

.p-2 {
  padding: $padding2;
}
.pb-2 {
  padding-bottom: $padding2;
}
.pt-2 {
  padding-top: $padding2;
}
.pr-2 {
  padding-right: $padding2;
}
.pl-2 {
  padding-left: $padding2;
}

.m-2 {
  margin: $padding2;
}
.mb-2 {
  margin-bottom: $padding2;
}
.mt-2 {
  margin-top: $padding2;
}
.mr-2 {
  margin-right: $padding2;
}
.ml-2 {
  margin-left: $padding2;
}

.subtitle {
  font-family: SharpGroteskBook19;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.fill {
  width: 100%;
}

.fill-page {
  min-height: 100vh;
}

.center,
.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.link {
  text-decoration: none;
  text-align: center;
  color: black;
  font-size: 13px;
  padding-top: 20px;
  cursor: pointer;
}

.app-info {
  padding: 10px;
  color: $color-grey;
}

.root {
  @include reskin-fonts('copy-M');
  position: relative;
  background-color: $color-white;
  text-align: center;
  min-height: 100vh;
  // height: 100vh;

  // display: flex;
  flex-direction: column;
  // align-items: stretch;
  > * {
    text-align: left;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }

  .container {
    max-width: 920px;
    margin: 0px auto;
  }
}

.invisible {
  visibility: hidden;
}

.fill-page {
  min-height: 100vh;
}

h1 {
  @include reskin-fonts('h1');
  font-weight: normal;
}

h2 {
  font-weight: normal;
}

.wrapper-signup {
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  overflow: hidden;
}

.wrapper-admin {
  // max-width: 1440px;
  // margin: 0 auto;
}

.wrap-long-words {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-word;

  -ms-hyphens: manual;
  -moz-hyphens: manual;
  -webkit-hyphens: manual;
  hyphens: manual;
}
