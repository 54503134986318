@import '../../common.scss';

.login-layout {
  position: relative;
  z-index: 1;
  height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: $reskin-beige;

  .header-row {
    width: 100%;
    max-width: 960px;
    margin-top: 60px;
    justify-content: center;

    .logo {
      width: 200px;
      height: 200px;

      > img {
        width: 200px;
        height: 200px;
      }
      align-self: center;
    }
  }

  .body {
    margin-bottom: 120px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .error {
    max-width: 500px;
    margin-left: 0px;
    margin-right: 0px;
  }

  p {
    font-size: 20px;
  }
  img {
    width: 179px;
    margin-bottom: 15px;
  }

  .form-area {
    width: 230px;
    background-color: $color-white;
    padding: 10px 10px 20px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 15px;

    @media (min-width: 375px) {
      width: 300px;
      padding: 10px 20px 20px;
    }
    @media (min-width: 500px) {
      width: 400px;
      padding: 10px 20px 20px 20px;
    }
    .input {
      margin-top: 10px;
    }
    .login-button {
      margin: 10px auto 0;
    }

    .login-button {
      margin-top: 30px;
      padding: 9px 20px;
      width: max-content;
      border-radius: 10px;
      background: $reskin-green;
      transition: opacity 150ms ease-out;

      @include reskin-fonts('statement-medium') {
        text-transform: uppercase;
        color: $reskin-beige;
      }

      &:hover {
        opacity: 0.7;
        transition: opacity 150ms ease-out;
      }
    }
    .forgot-password {
      text-align: center;
      font-size: 20px;
      padding-top: 20px;
      @include reskin-fonts('copy-S');
      text-decoration: underline;
      display: inline-block;
      width: auto;
    }

    label {
      display: none;
    }
  }
}
