@import '../../common.scss';

.error-message {
  text-align: center;

  .message {
    @include reskin-fonts('statement-medium');
    border-radius: 5px 5px 0px 0px;
    margin: 0px auto;
    display: inline-block;
    width: auto;
    padding: 10px;
    background-color: $reskin-green;
    color: $color-white;
    font-weight: bold;
  }

  &.error-modal {
    .message {
      padding: 0px;
      color: $color-secondary;
      background-color: $color-white;
    }
  }

  &.color-grey {
    .message {
      background-color: $color-grey;
    }
  }
}
