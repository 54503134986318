.processing-popup {
  text-align: center;
  padding-bottom: 10px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  max-width: 500px;
  .processing-loader {
    display: block;
    position: relative;
    width: 100%;
    height: 100px;
  }
}
.example-csv {
  cursor: pointer !important;
}

.invites-sent {
  font-size: 20px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);

  button {
    border: none;
    background-color: transparent;
    width: 24px;
    height: 24px;
    &:hover {
      cursor: pointer;
    }
  }
  .invite-number {
    background-color: black;
    color: white;
    padding: 0 4px;
    margin: 0 4px;
  }
}
