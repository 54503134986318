@import '../../common.scss';

.table-list {
  max-width: 670px;
  @media (max-width: 620px) {
    width: 100%;
  }
  .search-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 620px) {
      width: 100%;
      flex-direction: column;
      align-items: start;
      gap: 17px;
    }
    .search-email {
      margin-right: 18px;
      width: 441px;
      box-sizing: border-box;
      @media (max-width: 620px) {
        width: 100%;
      }
    }
    .search-icon {
      width: 15px;
      margin: 0 5px;
    }
    hr {
      padding: 5px 0 10px;
      border: none;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.16);
    }
    margin-bottom: 16px;

    .invite-button {
      width: 210px;
      text-wrap: nowrap;
      font-size: 12px;
    }
    .table-btn-box {
      width: fit-content;
      margin-right: 4px;
      .filter-button {
        height: 34px;
        width: 65px;
        font-size: 12px;
        @media (min-width: 768px) {
          margin-left: 10px;
        }
      }
      .clear-button {
        height: 34px;
        width: 65px;
      }
    }
    .download {
      text-wrap: nowrap;
      background: none;
      border: none;
      color: $color-chard-green;
      font-size: 14px;
    }
  }
  .invites {
    border: 1px solid $color-divider;
    font-family: Jost;
    min-height: 382px;
  }
}
