@import '../../common.scss';

.tooltip {
  position: relative;

  > label {
    display: none;
    position: absolute;
    padding: 4px;
    // background-color: rgba(176, 80, 69, 0.7);
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    text-align: center;
    font-size: 12px;
    white-space: nowrap;

    @include ignore-padding();
  }

  &:hover {
    > label {
      display: inline-block;
    }
  }

  &[data-align='top'] {
    > label {
      bottom: calc(100% + 5px);
      left: calc(50% - 50px);
    }
  }
  &[data-align='bottom'] {
    > label {
      bottom: calc(100% + 5px);
      width: 50px;
      left: calc(50% - 30px);
    }
  }
}
