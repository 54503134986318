@import '../../common.scss';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  background-color: $color-white;
  color: $color-chard-green;
  border: 1px solid $color-chard-green;
  border-radius: 100px;
  text-decoration: none;
  font-family: SharpGroteskBook19;
  padding: 8px 40px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color ease 0.15s, color ease 0.15s;
  text-transform: capitalize;

  &.icon {
    padding-left: 5px;
    padding-right: 5px;

    > .spacer {
      display: block;
      flex-grow: 1;
      margin-left: 5px;
    }
  }
  &:hover {
    background-color: $color-chard-green;
    color: white;
  }
  &.secondary {
    background-color: $color-secondary;
    color: white !important;
  }

  &.danger {
    background-color: $color-danger;
    color: white !important;
  }

  &.disabled {
    background-color: $color-grey;
    border: none;
    opacity: 0.75;
    color: $color-white;
    cursor: default;
  }

  &.size-small {
    padding: 5px 10px;
    font-size: 0.9em;
  }

  &.none {
    background-color: transparent;
    color: $color-chard-green;
    width: fit-content;
    border: none;
    padding: 0px;
  }
}
