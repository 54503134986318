@import '../../common.scss';

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: 'GothamCond-Book';
  button,
  p {
    margin: 10px 10px;
  }

  button {
    background-color: transparent;
    border: none;
    color: $color-chard-green;
    &:disabled {
      color: lightgray;
    }
  }
}
