@import '../../common.scss';

.loading-mask {
  position: relative;

  > .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;

    background-color: rgba(255, 255, 255, 0.5);

    > .animation {
      position: absolute;
      top: calc(50% - 80px);
      left: calc(50% - 80px);
      width: 160px;
      height: 160px;
    }
    // &::after{
    // 	content: '';
    // 	display: block;
    // 	width: 40px;
    // 	height: 40px;
    // 	position: absolute;
    // 	left: calc(50% - 20px);
    // 	top: calc(50% - 20px);
    // 	background-color: $color-secondary;

    // 	animation-name: spin;
    // 	animation-duration: 700ms;
    // 	animation-iteration-count: infinite;

    // 	opacity: 0.25;

    // }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: rotate(0deg);
    opacity: 0.25;
  }
}
