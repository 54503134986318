@import './Admin.scss';

.admin-main {
  &::after {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../assets/guides/Main\ -\ Account.png');
    background-repeat: no-repeat;
    opacity: 0.1;
    background-position: 50% 0%;
    pointer-events: none;
  }
  .header {
    border-bottom: 1px solid $color-divider;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 0;
    position: relative;
    .logout-text {
      display: none;
      position: absolute;
      right: 20px;
      top: 20px;
      @media (max-width: 768px) {
        display: block;
      }
    }
  }
  .mobile-selector {
    background-color: $web-almond-cream-light;
    padding: 8px 16px;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .member-list {
    padding: 35px 35px 36px 41px;
    justify-content: flex-start;
    box-sizing: border-box;
    gap: 135px;

    @media (max-width: 1450px) {
      // justify-content: space-between;
      gap: 20px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
      padding: 17px 16px;
      gap: 0;
    }
  }
  .main {
    background-color: white;
    box-sizing: border-box;
    h2 {
      color: $color-tertiary;
      font-size: 36px;
    }

    .actions {
      width: 100%;
      font-size: 24px;
      justify-content: flex-end;
      > * {
        margin-right: 10px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  .right-box {
    width: fit-content;
    .info {
      margin-bottom: 10px;

      .item-name {
        margin-bottom: 10px;
      }
      p {
        color: $color-green-dark;
      }
    }
    @media (max-width: 620px) {
      margin-left: 0;
      margin-top: 10px;
    }
  }
  .entry {
    width: 100%;
    font-size: 16px;
    padding: 8px 62px 8px 13px;
    align-items: center;
    border-bottom: 1px solid $color-divider;
    box-sizing: border-box;

    .status {
      font-size: 14px;
    }
    @media (max-width: 620px) {
      padding: 14px;
    }
    span {
      overflow: auto;
    }
    .controls {
      align-items: center;
      font-size: 16px;
      flex-grow: 0;

      height: 34px;
      width: auto;
      display: none;

      > * {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .resend-button,
    .controls .button {
      height: 100%;
      background-color: transparent;
      color: $color-chard-green;
      padding: 5px;
      &.resend-button {
        border: 1px solid $color-chard-green;
        padding: 8px 40px;
        width: 75px;
        margin-right: 5px;
        font-size: 12px;
      }
    }

    .controls .copy-link {
      border: none;
      width: 30px;
      height: 30px;
      color: $color-chard-green;
      opacity: 1;
    }

    &.with-controls {
      .controls {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    .remove-member-button {
      img {
        width: 20px;
        height: auto;
      }
    }
  }

  .modal-header {
    height: 66px;
    padding: 22px 26px 22px 24px;
    background: $web-almond-cream-light;
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    h5 {
      color: $color-chard-green;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  .content-box-sent {
    padding-left: 24px;
    padding-top: 23px;
    display: flex;
    flex-direction: column;
    gap: 17px;
    color: $color-chard-green;
  }
  .invite-popup {
    min-height: 211px;
    width: 523px;

    @media (max-width: 620px) {
      width: 95%;
      height: fit-content;
      padding-bottom: 20px;
    }
    .content-box {
      padding-left: 32px;
      .button-box {
        margin-top: 30px;
        display: flex;
        width: 100%;
        justify-content: flex-start;
        gap: 25px;
        @media (max-width: 620px) {
          flex-direction: column;
        }
        a {
          padding: 8px 40px;
          width: fit-content;
          box-sizing: border-box;
        }
      }
      .example-csv {
        color: $color-green-dark;
        text-decoration: underline;
        font-size: 18px;
        text-transform: capitalize;
        font-family: Jost;
      }
    }

    .button {
      width: 200px;
    }

    input {
      &:hover {
        cursor: pointer;
      }
    }

    hr {
      margin: 15px auto;
      width: 42px;
      border-top: 4px solid $color-secondary;
      opacity: 0.25;
    }
    .uploader {
      margin-bottom: 10px;
    }

    .error-box {
      padding: 10px;
    }
  }

  .filters-popup {
    padding: 20px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
    width: 500px;
    .close {
      cursor: pointer;
    }
    h2 {
      color: $color-chard-green;
    }
    ul {
      list-style: none;
      margin: 20px 0 20px 0;
      padding: 0;
      li {
        margin: 0;
        padding: 5px 0px;
        border-top: 1px dashed rgba(0, 0, 0, 0.16);
        display: flex;
        flex-direction: row;
        cursor: pointer;

        span {
          flex-grow: 1;
          line-height: 22px;
        }

        &:last-child {
          border-bottom: 1px dashed rgba(0, 0, 0, 0.16);
          margin-bottom: 200px;
        }
      }
    }
    .apply-button {
      font-size: 20px;
      padding: 0 10px;
    }
  }
}
