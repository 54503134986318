@import './Admin.scss';
.admin-corporations {
  .header {
    border-bottom: 1px solid $color-divider;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 0;
    position: relative;
    .logout-text {
      display: none;
      position: absolute;
      right: 20px;
      top: 20px;
      @media (max-width: 768px) {
        display: block;
      }
    }
  }
  .mobile-selector {
    background-color: $web-almond-cream-light;
    padding: 8px 16px;
    @media (min-width: 768px) {
      display: none;
    }
  }
  .member-list {
    width: 100%;
    background-color: white;
    padding: 41px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .left-box {
      width: 100%;
      .search-list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        gap: 18px;

        @media (max-width: 620px) {
          flex-direction: column;
          align-items: flex-start;
        }
        .create-btn {
          padding: 0 10px;
          display: none;
          @media (max-width: 620px) {
            display: flex;
          }
        }
        .search-email {
          width: 100%;
          box-sizing: border-box;
        }
        .search-icon {
          width: 15px;
          margin: 0 5px;
        }
        hr {
          padding: 5px 0 10px;
          border: none;
          border-bottom: 1px dashed rgba(0, 0, 0, 0.16);
        }
        .download {
          padding: 0 10px;
        }
      }
    }
    .right-box {
      width: 202px;
      .info {
        color: $color-green-dark;
        .item-name {
        }
      }
    }
  }

  .controls {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;

    > .spacer {
      flex-grow: 1;
      margin: 0p;
    }

    > * {
      flex-shrink: 0;
      margin-right: 10px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .admin-invoices {
    h1 {
      font-size: 32px;
      font-family: 'GothamCond-Medium';
    }
    h5 {
      font-family: 'GothamCond-Book';
      opacity: 0.7;
      font-size: 16px;
    }
    .invoice-list {
      z-index: 1;
      flex-direction: column;
      flex-grow: 0;
      font-family: 'Gotham-Book';
      font-size: 14px;

      .column-menu {
        .dash-nav {
          img {
            margin-top: -3px !important;
            height: 15px;
          }
        }
      }

      @media (min-width: 768px) {
        flex-direction: row;
        flex-grow: 1;
      }
      .column-main {
        width: auto;
        @media (min-width: 768px) {
          width: 100%;
        }
      }

      .button-icon:hover {
        cursor: pointer;
      }
    }
  }

  .table-scroll {
    overflow-x: auto;
    height: calc(100vh - 400px);
    border: 1px solid $color-divider;
    padding: 10px;
    table {
      border-collapse: collapse;
      width: 100%;
      h5 {
        color: rgba(0, 0, 0, 0.33);
      }
      tr {
        border-bottom: 1px dashed rgba(0, 0, 0, 0.16);
      }
      td {
        &:nth-child(4) {
          p {
            text-align: center;
            @media (min-width: 1000px) {
              text-align: left;
            }
          }
        }
      }
      th {
        padding: 10px 0;
        &:first-child {
          width: 30%;
        }
        &:nth-child(5) {
          h5 {
            text-align: right;
            @media (min-width: 500px) {
              text-align: left;
            }
          }
        }
      }
      td {
        a {
          text-decoration: none;
          color: black;
        }
      }
    }
  }
}
