@import '../../common.scss';

.admin-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  position: relative;
  width: 100%;
  padding: 13px 130px 13px 40px;
  border-bottom: 1px solid $color-divider;
  justify-content: center;
  box-sizing: border-box;

  .logout-text {
    display: none;
    position: absolute;
    right: 20px;
    top: 20px;
    @media (max-width: 768px) {
      display: block;
    }
  }

  @media (max-width: 620px) {
    height: fit-content;
    padding: 13px 16px;
  }
  &.disable-links {
    nav {
      pointer-events: none;
    }
  }

  h1 {
    color: $color-chard-green;
    font-size: 16px;
    line-height: 150%;
    font-family: Jost;
  }
  .second-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 638px;
    align-items: center;
    width: 100%;
    @media (max-width: 1440px) {
      justify-content: space-between;
      gap: 0;
    }

    .user-name {
      font-size: 40px;
      font-family: Simula;
    }
    .manage-btn {
      width: 218px;
      @media (max-width: 620px) {
        margin-top: 10px;
      }
    }
    @media (max-width: 620px) {
      flex-direction: column;
      align-items: start;
    }
  }
}
