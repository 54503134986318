@import './mixins.scss';
@import './reskin-2023.scss';

$color-grey: #777;

$color-black: #000;
$color-white: #fff;

$color-off-white: #eee;

$color-primary: $color-black;
$color-secondary: #b05045;
$color-tertiary: #ba7729;

$color-danger: #ff0000;
$color-divider: #e0e0e0;

$dashed-grey: 1px dashed rgba(0, 0, 0, 0.16);

$color-blue: #3a9ede;
$color-cream: #fef7eb;

$color-chard-green: #27483e;
$color-green-dark: #49666a;
button {
  outline: none;
}
