@import '../../common.scss';

.input-checkbox {
  cursor: pointer;
  label {
    font-size: 20px;
    text-align: left;

    a {
      color: black;
    }
  }

  // custom checkbox:
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .container input {
    // hide default checkbox:
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .container input:checked ~ .checkmark {
    // background when checkbox is checked:
    background-color: $color-secondary;
    border: solid 2px $color-off-white;
  }

  .checkmark:after {
    // checkmark indicator (hidden when not checked):
    content: '';
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    // show checkmark when checked:
    display: block;
  }

  .container .checkmark:after {
    // style checkmark
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  label,
  a {
    @include reskin-fonts('statement-small') {
      text-transform: uppercase;
    }
  }

  label input {
    left: -10px;
    top: -5px;
  }

  input[type='checkbox'] {
    left: 0px;
    opacity: 1;
    padding: 0px;
    appearance: none;
    cursor: pointer;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 24px;
    height: 24px;
    border: 1px solid $reskin-green;
    border-radius: 15px;
    transform: rotate(10deg);
    display: grid;
    place-content: center;
  }

  input[type='checkbox']::before {
    margin: 4px 0px 0px 4px;
    content: '';
    width: 24px;
    height: 24px;
    transform: scale(0);
    transition: 100ms transform ease-in-out;
    background-color: CanvasText;
    clip-path: path(
      'M8.96484 14.5879C9.35547 14.5879 9.66471 14.4186 9.89258 14.0801L14.1797 7.40039C14.2513 7.28971 14.3099 7.17578 14.3555 7.05859C14.4076 6.94141 14.4336 6.82747 14.4336 6.7168C14.4336 6.45638 14.3359 6.24479 14.1406 6.08203C13.9453 5.91927 13.724 5.83789 13.4766 5.83789C13.1445 5.83789 12.8678 6.01367 12.6465 6.36523L8.94531 12.332L7.20703 10.1445C7.08333 9.99479 6.95964 9.88737 6.83594 9.82227C6.71224 9.75716 6.57227 9.72461 6.41602 9.72461C6.16211 9.72461 5.94401 9.81576 5.76172 9.99805C5.57943 10.1803 5.48828 10.4017 5.48828 10.6621C5.48828 10.8965 5.57943 11.1276 5.76172 11.3555L8.01758 14.0898C8.1543 14.2526 8.29753 14.3763 8.44727 14.4609C8.60352 14.5456 8.77604 14.5879 8.96484 14.5879Z'
    );
  }

  input[type='checkbox']:checked::before {
    transform: scale(1);
  }
}
