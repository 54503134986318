@import './Admin.scss';

.admin-usage {
  .datepicker {
    padding: 7px;
  }

  .summary {
    border-bottom: $dashed-grey;

    margin-top: 7px;
    margin-bottom: 7px;
    padding-bottom: 7px;

    .button {
      margin-left: 7px;
    }
  }
}
