@import '../../common.scss';

.terminate-admin {
  padding: 20px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  max-width: 500px;
  text-transform: capitalize;
  h2 {
    color: $color-chard-green;
  }
  p {
    font-size: 20px;
    padding: 10px 0;
  }
  textarea {
    width: 100%;
    min-height: 100px;
    margin: 10px 0px;
    padding: 10px;
    resize: none;
    @include ignore-padding();
  }

  .cancel-button {
    background-color: $color-chard-green;
    color: $color-white;
    font-size: 20px;
    width: 100px;
    margin-right: 12px;
  }
  .delete-button {
    font-size: 20px;
  }
}
