@import '../../common.scss';

.create-admin {
  padding: 20px 20px 20px 20px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  .input-text {
    margin-left: 5px;
    color: $color-chard-green;
  }
  img {
    cursor: pointer;
  }
  .input {
    label {
      color: $color-chard-green;
      text-wrap: nowrap;
    }
    margin-bottom: 10px;
  }

  h4 {
    color: $color-chard-green;
  }
  .input-number {
    input {
      font-size: 16px;
    }

    padding: 0 10px;
    margin-right: 10px;
    label {
      font-size: 16px;
      width: auto;
      margin-right: 10px;
    }
    button {
      font-size: 36px;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      touch-action: manipulation;
      cursor: pointer;
    }
  }
  .input-select select {
    color: black;
  }

  .members-container {
    padding: 5px 0 5px 0;
    margin: 5px 0 10px 0;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      padding-left: 10px;
    }
    .member-calc {
      display: flex;
      @media (min-width: 768px) {
        display: flex;
      }
      align-items: center;
      font-size: 16px;
      font-weight: normal;
    }
    .total-cost {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 10px;
      font-size: 28px;
      span {
        font-size: 19px;
      }
    }
  }

  .create-button {
    font-size: 20px;
    margin-right: 20px;
    padding: 0 10px;
  }
}
