@import '../../common.scss';

.modal-container {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10000;
}

.modal-bg {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.25);
}

.modal {
  display: inline-block;
  min-width: 300px;
  width: 80vw;
  color: black;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include ignore-padding();

  &.size-medium {
    width: 80vw;
  }
}
