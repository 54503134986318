@import './Admin.scss';
.body-frame {
  // height: 100vh;
  padding: 20px 32px;
  margin-right: 116px;
  background-color: white;
  box-shadow: 0px 4px 23px rgba(57, 3, 39, 0.05);

  h1 {
    margin-bottom: 24px;
  }

  .plan-calc {
    margin: 20px 0 60px 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    .per-year {
      padding-left: 8px;
      .cost {
        font-size: 22px;
      }
      .text {
        font-size: 14px;
      }
    }
  }

  .account-padding {
    padding-top: 70px;
  }
}

.admin-invoices {
  h2 {
    font-size: 36px !important;
  }
  h5 {
    opacity: 0.7;
    font-size: 16px;
  }
  .invoices {
    width: 100%;
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    max-width: 960px;
  }
  .header {
    border-bottom: 1px solid $color-divider;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 0;
    position: relative;
    .logout-text {
      display: none;
      position: absolute;
      right: 20px;
      top: 20px;
      @media (max-width: 768px) {
        display: block;
      }
    }
  }
  .mobile-selector {
    background-color: $web-almond-cream-light;
    padding: 8px 16px;
    @media (min-width: 768px) {
      display: none;
    }
  }

  // .invoice-list {
  // 	z-index: 1;
  // 	flex-direction: column;
  // 	flex-grow: 0;
  // 	font-family: "Gotham-Book";
  // 	font-size: 14px;

  // 	.column-menu {
  // 		.dash-nav {
  // 			img {
  // 				margin-top: -3px !important;
  // 				height: 15px;
  // 			}
  // 		}
  // 	}

  // 	@media (min-width: 768px) {
  // 		flex-direction: row;
  // 		flex-grow: 1;
  // 	}
  // 	.column-main {
  // 		width: auto;
  // 		@media (min-width: 768px) {
  // 			width: 100%;
  // 		}
  // 	}

  // 	.button-icon:hover {
  // 		cursor: pointer;
  // 	}
  // }
}

table {
  border-collapse: collapse;
  width: 100%;
  h5 {
    color: rgba(0, 0, 0, 0.33);
  }
  tr {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.16);
  }
  th {
    padding: 10px 0;
    &:first-child {
      width: 35%;
    }
  }
  td {
    padding: 10px 0;
  }
}
