@import './Admin.scss';
.setup-failed {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  padding: 20px;
  text-align: center;
  font-size: 18px;
  .retry {
    background-color: $color-chard-green;
    margin: 10px auto;
    color: $color-white;
  }
}
