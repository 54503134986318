.button-icon {
  background: transparent;
  border: none;
  outline: none;

  cursor: pointer;

  &:focus {
    border: none;
    outline: none;
  }
}
