/* Design system */

// @import "./hide_onetrust.scss";

$reskin-green: #2f473e;
$reskin-secondary-green: rgba(47, 71, 62, 0.4);
$reskin-beige: #f2efeb;
$reskin-secondary-beige: rgba(242, 239, 235, 0.4);
$reskin-light-green: #d3e1c3;
$reskin-secondary-light-green: rgba(211, 225, 195, 0.4);
$reskin-peach: #e5ccb4;
$reskin-sky-blue: #b9ccc9;
$reskin-dust-blue: #66708e;
$reskin-white: #ffffff;

$web-chard-green: #27483e;
$web-chard-medium: #5d766e;
$web-chard-light: #93a39f;
$web-spring-green: #cfe2c0;
$web-spring-green-light: #e7f1df;
$web-almond-cream: #f2efeb;
$web-almond-cream-light: #f9f7f5;
$web-white: #ffffff;
$web-divider: #e0e0e0;
$web-light: #e5edee;

$system-error: #ef5350;
$system-warning: #ff9800;
$system-info: #03a9f4;
$system-success: #4caf50;

/* h1-5, copy-S/M/L, statement-medium/small */
@mixin reskin-fonts($type, $important: '') {
  text-decoration: none #{$important};
  text-transform: none #{$important};
  color: $reskin-green #{$important};

  @if $type == 'h1' {
    font-family: 'Simula' #{$important};
    font-style: normal #{$important};
    font-weight: 400 #{$important};
    font-size: 52px #{$important};
    line-height: 68px #{$important};
    color: $reskin-green;
    span {
      font-family: 'Simula Italic' $important;
    }

    @content;
  }

  @if $type == 'h2' {
    font-family: 'Simula' #{$important};
    font-style: normal #{$important};
    font-weight: 400 #{$important};
    font-size: 42px #{$important};
    line-height: 55px #{$important};
    text-transform: none #{$important};
    color: $reskin-green;
    span {
      font-family: 'Simula Italic' #{$important};
    }

    @content;
  }

  @if $type == 'h3' {
    font-family: 'Simula' #{$important};
    font-style: normal #{$important};
    font-weight: 400 #{$important};
    font-size: 32px #{$important};
    line-height: 42px #{$important};
    text-transform: none #{$important};
    color: $reskin-green;
    span {
      font-family: 'Simula Italic' #{$important};
    }

    @content;
  }

  @if $type == 'h4' {
    font-family: 'Simula' #{$important};
    font-style: normal #{$important};
    font-weight: 400 #{$important};
    font-size: 22px #{$important};
    line-height: 29px #{$important};
    text-transform: none #{$important};
    color: $reskin-green;
    span {
      font-family: 'Simula Italic' #{$important};
    }

    @content;
  }

  @if $type == 'copy-L' {
    font-family: 'SharpGroteskBook19' #{$important};
    font-style: normal #{$important};
    font-weight: 400 #{$important};
    font-size: 32px #{$important};
    line-height: 41px #{$important};
    text-transform: none #{$important};
    color: $reskin-green;
    @content;
  }

  @if $type == 'copy-M' {
    font-family: 'SharpGroteskBook19' #{$important};
    font-style: normal #{$important};
    font-weight: 400 #{$important};
    font-size: 16px #{$important};
    line-height: 28px #{$important};
    text-transform: none #{$important};
    color: $reskin-green;
    @content;
  }

  @if $type == 'copy-S' {
    font-family: 'SharpGroteskBook19' #{$important};
    font-style: normal #{$important};
    font-weight: 400 #{$important};
    font-size: 16px #{$important};
    line-height: 26px #{$important};
    text-transform: none #{$important};
    color: $reskin-green;
    @content;
  }

  @if $type == 'statement-medium' {
    font-family: 'SharpGroteskBook19' #{$important};
    font-style: normal #{$important};
    font-weight: 400 #{$important};
    font-size: 16px #{$important};
    line-height: 20px #{$important};
    letter-spacing: 0.1em #{$important};
    text-transform: uppercase #{$important};
    color: #000000 #{$important};
    text-transform: none #{$important};
    color: $reskin-green;
    @content;
  }

  @if $type == 'statement-small' {
    font-family: 'SharpGroteskBook19' #{$important};
    font-style: normal #{$important};
    font-weight: 400 #{$important};
    font-size: 12px #{$important};
    line-height: 15px #{$important};
    letter-spacing: 0.1em #{$important};
    text-transform: uppercase #{$important};
    color: #000000 #{$important};
    text-transform: none #{$important};
    color: $reskin-green;
    @content;
  }
}

$typography-heading: (
  1: 3.25rem,
  2: 3rem,
  3: 2.5rem,
  4: 1.625rem,
  5: 1.25rem,
  6: 1rem,
);

$typography-body: (
  1: 1rem,
  2: 0.875rem,
  3: 0.75rem,
);

@each $heading-type, $font-size in $typography-heading {
  h#{$heading-type},
  .h#{$heading-type} {
    margin: 0;
    padding: 0;
    font-family: Simula;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size;
    font-variant-numeric: lining-nums;
    line-height: 120%;
  }
  h#{$heading-type} > i,
  .h#{$heading-type}-italic {
    font-family: 'Simula Italic';
    font-style: normal;
    font-weight: 400;
    font-size: $font-size;
    font-variant-numeric: lining-nums;
    line-height: 120%;
  }
}

@each $body-type, $font-size in $typography-body {
  .body-#{$body-type} {
    font-family: SharpGroteskBook19;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size;
    line-height: 150%;
    letter-spacing: 0.03125rem; /* 0.5px */
  }
  .body-#{$body-type}-bold {
    font-family: SharpGroteskMedium20;
    font-style: normal;
    font-weight: 500;
    font-size: $font-size;
    line-height: 150%;
    letter-spacing: 0.03125rem; /* 0.5px */
  }
  .body-#{$body-type}-textlink {
    font-family: SharpGroteskBook19;
    font-style: normal;
    font-weight: 400;
    font-size: $font-size;
    text-decoration: underline;
    line-height: 150%;
    letter-spacing: 0.03125rem; /* 0.5px */
  }
}

.subtitle {
  font-family: SharpGroteskBook19;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  text-transform: uppercase;
  line-height: normal;
  letter-spacing: 0.09375rem; /* 1.5px */
}

.subtitle-bold {
  font-family: SharpGroteskMedium20;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  text-transform: uppercase;
  line-height: normal;
  letter-spacing: 0.09375rem; /* 1.5px */
}

.subtitle-large {
  font-family: SharpGroteskBook19;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: normal;
  letter-spacing: 0.09375rem; /* 1.5px */
}

button,
.button {
  font-family: SharpGroteskBook19;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  text-transform: capitalize;
  line-height: normal;
  letter-spacing: 0.03125rem; /* 0.5px */
  background-color: transparent;
  color: $web-chard-green;
  padding: 0 0;
}
.button {
  padding: 0 10px;
}

.overline {
  font-family: SharpGroteskBook19;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: normal;
  letter-spacing: 0.09375rem; /* 1.5px */
}

link,
*:any-link {
  color: inherit;
}
