.uploader {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: stretch;

  > .button {
    width: 100%;
  }
}

input.fileUpload[type='file'] {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
