@import './SuperAdmin.scss';
.super-admin-popup {
  padding: 20px;
  p {
    margin-left: 5px;
  }
  color: $color-green-dark;
  .invite-single-member-button {
    margin-top: 10px;

    &.disabled {
      background-color: $color-grey;
      color: $color-white;
      border: none;
      &:hover {
        background-color: $color-grey;
        color: $color-white;
      }
    }
  }
}

.modal-header {
  height: 66px;
  padding: 22px 26px 22px 24px;
  background: $web-almond-cream-light;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  h5 {
    color: $color-chard-green;
  }
  img {
    width: 20px;
    height: 20px;
  }
}
