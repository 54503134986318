@import '../../common.scss';

.custom-dropdown {
  width: 100%;
  position: relative;
  border: 1px solid $color-divider;

  .selected-option {
    width: 100%;
    padding: 6px 16px;
    background-color: $color-white;
    cursor: pointer;
    box-sizing: border-box;
    transition: background-color 0.3s;
    text-transform: capitalize;
    border-radius: 4px;

    &:hover {
      background-color: #f5f5f5;
    }
  }
  .arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 12px;
    height: 12px;
    transform: translateY(-50%);
    transition: all 0.3s;
    &.rotated {
      transform: translateY(-50%) rotate(180deg);
    }
  }
  .options {
    position: absolute;
    top: 32px;
    left: -1px;
    width: 100%;
    border: 1px solid $color-divider;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    border-top: none;
    text-transform: capitalize;
    padding: 8px 0;
    background-color: $color-white;

    .option-item {
      padding: 6px 16px;
      background-color: $color-white;
      cursor: pointer;
      transition: background-color 0.3s;
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
