@import '../../common.scss';

.switch-admin {
  padding: 20px;
  color: $color-chard-green;
  text-align: center;
  width: 300px;
  textarea {
    width: 100%;
    min-height: 200px;
    margin: 10px 0px;
    padding: 10px;

    @include ignore-padding();
  }
}
