.flex {
  display: flex;
  width: 100%;

  > .grow,
  > .spacer {
    flex-grow: 1;
  }

  &.direction-row {
    flex-direction: row;
    width: 100%;
  }

  &.direction-column {
    flex-direction: column;
    width: 100%;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-center {
    align-items: center;
  }

  &.align-stretch {
    align-items: stretch;
  }

  &.justify-space-between {
    justify-content: space-between;
  }

  &.justify-space-around {
    justify-content: space-around;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  &.size-auto {
    width: auto;
  }

  > .align-end {
    align-self: flex-end;
  }

  > .align-start {
    align-self: flex-start;
  }

  > .align-center {
    align-self: center;
  }

  > .align-stretch {
    align-self: stretch;
  }
}
