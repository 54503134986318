@import '../../common.scss';

.summary {
  .intro {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 374px 540px;

    .upper {
      grid-row: 1;
      grid-column: 1 / 3;
      padding: 80px 0px;
      background: $reskin-green;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      img,
      h1 {
        color: $reskin-beige;
        margin: 0 auto;
      }

      img {
        width: 35%;
      }

      h1 {
        width: 70%;
        text-align: center;
        text-transform: none;
        font-size: 42px;
        text-align: center;
        line-height: 55px;
      }
    }

    .lower_text {
      grid-row: 2;
      grid-column: 1;
      background: $reskin-beige;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 67px 37px;

      .row {
        width: 100%;
        display: grid;
        grid-template-columns: 65px auto;
        align-items: center;
        @include reskin-fonts('h4');

        img {
          margin-right: 23px;
          justify-self: center;
        }

        h5 {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    .lower_image {
      grid-row: 2;
      grid-column: 2;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: 100%;
      grid-template-rows: 374px 540px 375px;

      .lower_image {
        grid-row: 3;
        grid-column: 1;
      }

      .upper {
        img {
          width: 70%;
        }

        h1 {
          font-size: 175%;
          line-height: 50px;
          padding-top: 30px;
          width: 90%;
        }
      }
    }
  }
}
