@import '../../common.scss';

.menu {
  background-color: rgb(150, 150, 150);
  list-style: none;

  position: absolute;
  top: 0px;
  right: 0px;

  padding: 20px;

  @include ignore-padding();

  z-index: 1000;

  li a {
    color: black;
    text-decoration: underline;
  }

  width: 200px;
}

.clearMenu {
  margin-right: 200px;
}
