@import '../../common.scss';

.how-it-works {
  background-color: $reskin-beige;
  padding: 80px 0px;
  text-align: center;
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 650px;

  > * {
    margin: 0 auto;
  }

  h1 {
    @include reskin-fonts('h1');
    text-transform: none;
    span {
      font-family: Simula Italic;
    }
  }

  p {
    @include reskin-fonts('h2');

    &.narrow {
      width: 45%;

      @media screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 52px;
        width: 90%;
        margin-top: 20px;
      }
    }
  }

  .how-works-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .how-works-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 24px 0;
      padding: 36px;
      height: max-content;
      width: 265px;
      max-width: 350px;

      p {
        @include reskin-fonts('copy-S');
        padding-top: 40px;
      }

      p.title {
        @include reskin-fonts('h3');
        padding-top: 60px;
      }

      &:nth-of-type(1) {
        img {
          height: auto;
          width: 95px;
        }

        p.title {
          width: 200px;
        }
      }

      &:nth-of-type(2) img {
        width: 80px;
        height: auto;
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;

      .how-works-card {
        width: unset;
      }
    }
  }
}
