@import '../../common.scss';

.input-number {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
  height: fit-content;

  input {
    border: none;
    height: 35px;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 30px;
    width: 50px;
  }

  button {
    @include reskin-fonts('copy-M') {
      font-size: 36px;
      line-height: unset;
    }

    background: $reskin-beige !important;
    color: $reskin-green !important;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border: none;

    &:active,
    &:hover,
    &:focus {
      background: $reskin-green;
      color: white;
    }
  }

  label {
    text-align: center;
    display: flex;
    align-items: center;
    gap: 12px;

    input {
    }
  }

  .button-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
