@import '../../common.scss';

.banner {
  margin-bottom: 18px;

  img {
    max-width: 100%;
  }

  #imgFeelBetterBanner {
    display: none;
  }

  @media (min-width: 768px) {
    #imgFeelBetterBannerMobile {
      display: none;
    }
    #imgFeelBetterBanner {
      display: inline;
    }
  }
}
