@import '../../common.scss';
.input {
  display: inline-block;
  color: $color-chard-green;
  display: flex;
  text-align: left;
  border: 1px solid $color-divider;
  padding: 9px 12px;
  border-radius: 30px;
  box-sizing: border-box;
  ::placeholder {
    color: $color-chard-green;
    opacity: 1; /* Firefox */
  }

  &.inverted {
    border-bottom: 1px solid white;
    input,
    label {
      color: $color-white;
    }
  }
  .icon {
    width: 24px;
    height: 24px;
    color: $color-chard-green;
    fill: $color-chard-green;
  }
  input {
    margin-left: 5px;
    width: 100%;
    border: none;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    border-width: 1px;
    color: $color-chard-green;

    &:focus {
      outline: none;
    }

    &::placeholder,
    &::-webkit-input-placeholder {
      @include reskin-fonts('statement-medium');
      letter-spacing: 0.5px;
      color: rgba(47, 71, 62, 0.4);

      @media screen and (max-width: 900px) {
        width: 100%;
        margin-right: 0px;
      }
    }
  }
}

select {
  width: calc(100% - 50px);
  display: block;

  @media screen and (max-width: 900px) {
    width: 50%;
  }
}

.edit-button {
  color: #93a8aa;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  cursor: pointer;
}
