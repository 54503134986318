@import '../../common.scss';
.input-select {
  display: inline-block;
  padding: 20px 0px 5px;
  display: flex;
  flex-direction: column;
  text-align: left;

  select {
    all: unset;
    appearance: none;
    width: unset;
    max-width: unset;
    min-width: unset;

    @include reskin-fonts('statement-medium') {
      color: $reskin-beige;
    }

    border-style: solid;
    background-color: transparent;
    border-color: rgba(47, 71, 62, 0.3);
    color: rgba(47, 71, 62, 0.3);
    border-radius: 10px;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    padding: 18px 20px;
  }

  &.inverted {
    border-bottom: 1px solid white;
    select,
    label {
      color: $color-white;
    }
    select {
      background: url(../../assets/images/down-arrow.svg) no-repeat right 5px
        center/14px 14px;
    }
  }
}
