@import '../../common.scss';
.welcome-modal-wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;

  z-index: 999;
  .mask {
    // position: absolute;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-divider;
  }
  .welcome-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-white;
    width: 858px;
    height: 446px;
    display: flex;

    @media (max-width: 620px) {
      width: 95%;
    }
    .left-box {
      width: 359px;
      height: 446px;
      @media (max-width: 620px) {
        display: none;
      }
    }

    .right-box {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      gap: 16px;
      padding: 21px 39px;
      position: relative;
      .top-close-btn {
        position: absolute;
        top: 21px;
        right: 17px;
        width: 24px;
        height: 24px;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .close-btn {
        width: 251px;
        margin-top: 4px;
      }
    }
  }
}
