@import '../../common.scss';

.review-container {
  background-color: $reskin-beige;
  padding-top: 80px;

  img {
    padding-bottom: 5px;
    max-width: 333px;
    margin: 0px 10px;
  }

  display: flex;
  max-width: 100%;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 70px;

  @media screen and (max-width: 1075px) {
    display: grid;

    img {
      margin: 10px;
    }
  }
}
