@import '../../common.scss';
.insights-warpper {
  h2 {
    margin: 56px 0 0 49px;
    color: $color-chard-green;
  }
  .mobile-selector {
    background-color: $web-almond-cream-light;
    padding: 8px 16px;
    @media (min-width: 768px) {
      display: none;
    }
  }
  .header {
    border-bottom: 1px solid $color-divider;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 0;
    position: relative;
    .logout-text {
      display: none;
      position: absolute;
      right: 20px;
      top: 20px;
      @media (max-width: 768px) {
        display: block;
      }
    }
  }
}
