@import '../../common.scss';

.column-menu {
  margin: 0 20px 30px;
  @media (min-width: 768px) {
    margin: 0;
  }

  .dash-nav {
    img {
      margin-top: -3px !important;
      height: 15px;
    }
  }
}
.plan-wrapper {
  .mobile-selector {
    background-color: $web-almond-cream-light;
    padding: 8px 16px;
    @media (min-width: 768px) {
      display: none;
    }
  }

  .request-help-text {
    width: 100%;
    display: block;
    margin-top: 70px;
    text-align: start;
    color: $color-green-dark;
    position: absolute;
    bottom: 36px;
    padding-left: 40px;
    @media (max-width: 620px) {
      text-align: start;
      padding: 0 16px;
      box-sizing: border-box;
      position: unset;
      margin-bottom: 10px;
    }
  }
}
.header {
  border-bottom: 1px solid $color-divider;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 0;
  .logout-text {
    display: none;
    position: absolute;
    right: 20px;
    top: 20px;
    @media (max-width: 768px) {
      display: block;
    }
  }
}
.column-main {
  .edit-plan {
    width: 100%;
    background-color: white;
    box-sizing: border-box;
    position: relative;
    height: 100%;
    padding-top: 20px;
    padding-right: 20px;

    .billing-section {
      margin-bottom: 20px;
    }
  }
  h4 {
    margin: 0 0 10px 0;
    text-transform: capitalize;
  }
  input:read-only {
    opacity: 0.5;
  }

  .edit-plan-top {
    width: 100%;
    background-color: $color-white;
    .confirm-cancel {
      display: flex;
      align-items: center;
      margin-top: 20px;
      gap: 10px;

      &:hover {
        cursor: pointer;
      }
      .grid-loader {
        width: 14px;
      }
    }
    .confirm-button {
      min-width: 92px;
      height: 20px;
      background-color: $color-white;
      color: $color-chard-green;
      font-size: 16px;
      padding: 15px 10px;
      margin-bottom: 0;
      &:hover,
      &.loading {
        color: $color-white;
        background-color: $color-chard-green;
      }
    }
    .cancel-link {
      color: $color-chard-green;
      text-decoration: underline;
    }
  }
  .plan-calc {
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 20px 20px;

    .input-number,
    .input-number button {
      font-size: 26px;
    }
    .input-number,
    .plan-details {
      display: flex;
      flex-direction: row;
      gap: 14px;
      @media (max-width: 620px) {
        width: 100%;
      }
    }

    .plan-details-plan {
      display: flex;

      @media (max-width: 620px) {
        flex-direction: column;
        gap: 10px;
      }
      h4 {
        margin-top: 20px;
      }
      .cost {
        margin-left: 5px;
        font-size: 22px;
        line-height: 27px;
      }

      .text {
        font-size: 14px;
        line-height: 17px;
        margin-left: 5px;
      }
      input {
        font-size: 14px;
        color: $color-chard-green;
        border: 1px solid $web-chard-light;
        border-radius: 8px;
        outline: none;
      }

      .plan-count {
        display: flex;
        align-items: center;
        margin-left: 5px;
        .per-year {
          display: flex;
          align-items: center;
        }
      }
    }

    .additional-payment {
      max-width: 450px;
      margin-top: 10px;
      font-size: 14px;
    }
  }

  .bottom-div {
    padding-left: 20px;
    .grow {
      border: none;
      border-bottom: 1px solid $web-light;
      border-radius: 0;
      padding-left: 0;
      @media (max-width: 620px) {
        display: flex;
        align-items: center;
        label {
          font-size: 12px;
        }
        p {
          font-size: 14px;
        }
      }

      &.no-border {
        border: none;
      }
      label {
        // width: 250px;
        font-size: 14px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: $web-chard-medium;
      }
      input {
        letter-spacing: 0.5px;
        font-size: 14px;
        color: $web-chard-green;
      }
    }
    .manage-account-box {
      label {
        width: 250px;
      }
    }
  }
  .account-padding {
    margin-top: 0px;
  }
  .controls-box {
    display: flex;
    flex-direction: row;
    gap: 18px;
    @media (max-width: 620px) {
      flex-direction: column;
    }
    a {
      padding: 0 10px;
    }
  }
}

.change-password {
  background-color: $color-tertiary;
}
.save-change-button {
  width: 197px;
  margin-top: 47px;
}
.change-password-popup {
  width: 500px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  height: 300px;
  @media (max-width: 620px) {
    height: fit-content;
    width: 95%;
  }
  .modal-header {
    height: 66px;
    padding: 22px 26px 22px 24px;
    background: $web-almond-cream-light;
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    h5 {
      color: $color-chard-green;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  .change-password-input {
    margin: 0 20px 0;

    @media (max-width: 620px) {
      font-size: 12px;
      label {
        width: fit-content;
      }
    }
  }

  .change-password-button {
    margin: 15px auto 0;
    width: 300px;
    @media (max-width: 620px) {
      width: fit-content;
      margin-bottom: 10px;
      padding: 0 10px;
    }
  }

  .input-label {
    margin: 20px 0 5px 25px;
    color: $color-chard-green;
  }
}
