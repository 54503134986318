@import './SuperAdmin.scss';

.super-admin-users {
  background-color: white;

  .mobile-selector {
    background-color: $web-almond-cream-light;
    padding: 8px 16px;
    @media (min-width: 768px) {
      display: none;
    }
  }
  .super-admin-main {
    padding: 20px;
    color: $color-green-dark;

    .invite-button {
      width: 170px;
      font-size: 20px;
    }
    .actions {
      margin-bottom: 20px;
    }

    table {
      th {
        width: 1000px;
      }
      th:last-child {
        width: 50px;
      }
    }
  }
}
