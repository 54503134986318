.dashboard {
  background-color: transparent;

  .dashboard-header {
    background-color: transparent;

    img {
      padding: 41px 0 0 116px;
    }
  }

  .dashboard-content {
    display: flex;
    flex-direction: row;

    height: 100vh;

    .dashboard-menu {
      width: 33vw;
      padding: 20px 0 0 116px;

      .dash-nav {
        display: flex;
        text-decoration: none;
        color: black;
        font-weight: 700;
      }
    }

    .dashboard-body {
      flex-grow: 1;
    }
  }

  .download-button {
    background-color: transparent;
    border: none;
  }
}
